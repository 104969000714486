import { betweenWidth, fromWidth, onlyWidth, toWidth } from './media-query';

export const responsive = (props) => props.responsive;

export interface ResponsiveProps {
  responsive?: any;
}

export const fromScreen = (minWidth: number) => `@media screen and ${fromWidth(minWidth)}`;

export const notFromScreen = (minWidth: number) => `@media not screen and ${fromWidth(minWidth)}`;

export const toScreen = (maxWidth: number) => `@media screen and ${toWidth(maxWidth)}`;

export const betweenScreen = (minWidth: number, maxWidth: number) =>
  `@media screen and ${betweenWidth(minWidth, maxWidth)}`;

export const onlyScreen = (width: number, nextBreakPoint: number) =>
  `@media screen and ${onlyWidth(width, nextBreakPoint)}`;

export const mouseDevice = () => '@media (hover: hover) and (pointer: fine)';
export const touchDevice = () => '@media (hover: none) and (pointer: coarse)';

export const isFirst = (index: number) => index === 0;
export const isLast = (index: number, arr: any[]) => index === arr.length - 1;

export interface BreakpointInfo {
  breakpoint: number;
}

export interface QueryInfo {
  query: string;
}

export const toMediaQuery = <T extends BreakpointInfo>(data: T[]): (T & QueryInfo)[] => {
  let lastBreakpoint;
  return data.map(({ breakpoint, ...rest }, index, arr) => {
    let query;
    if (isFirst(index)) {
      query = toScreen(breakpoint);
    } else if (isLast(index, arr)) {
      query = fromScreen(breakpoint);
    } else {
      query = betweenScreen(lastBreakpoint, breakpoint);
    }
    lastBreakpoint = breakpoint;
    return { ...rest, breakpoint, query } as T & QueryInfo;
  });
};

export const responsiveOnBreakpoints = <T extends BreakpointInfo>(
  p: T[],
  cssFn: (item: T & QueryInfo) => string
) => {
  return toMediaQuery(p).reduce(
    (currentValue, item) => `${currentValue}
    ${item.query} {
      ${cssFn(item)}
    }`,
    ''
  );
};

// export const fromWidth = (minWidth: number) => `@media (min-width: ${minWidth}px)`;
//
// export const toWidth = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;
//
// export const betweenWidth = (minWidth: number, maxWidth: number) =>
//   `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
//
// export const onlyWidth = (width: number, nextBreakPoint: number) =>
//   `@media (min-width: ${width}px) and (max-width: ${nextBreakPoint - 0.02}px)`;
