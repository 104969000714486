const BUTTON_CSS_VARIABLES = {
  PRIMARY: {
    TEXT_COLOR: '--btn-primary-text-color',
    TEXT_COLOR_HOVER: '--btn-primary-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-primary-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-primary-text-color-disabled',
    BG_COLOR: '--btn-primary-bg-color',
    BG_COLOR_HOVER: '--btn-primary-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-primary-bg-color-active',
    BG_COLOR_DISABLED: '--btn-primary-bg-color-disabled',
  },
  PRIMARY_WHITE: {
    TEXT_COLOR: '--btn-primary-white-text-color',
    TEXT_COLOR_HOVER: '--btn-primary-white-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-primary-white-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-primary-white-text-color-disabled',
    BG_COLOR: '--btn-primary-white-bg-color',
    BG_COLOR_HOVER: '--btn-primary-white-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-primary-white-bg-color-active',
    BG_COLOR_DISABLED: '--btn-primary-white-bg-color-disabled',
  },
  PRIMARY_OUTLINE: {
    TEXT_COLOR: '--btn-primary-outline-text-color',
    TEXT_COLOR_HOVER: '--btn-primary-outline-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-primary-outline-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-primary-outline-text-color-disabled',
    BG_COLOR: '--btn-primary-outline-bg-color',
    BG_COLOR_HOVER: '--btn-primary-outline-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-primary-outline-bg-color-active',
    BG_COLOR_DISABLED: '--btn-primary-outline-bg-color-disabled',
    BORDER_COLOR: '--btn-primary-outline-border-color',
    BORDER_COLOR_HOVER: '--btn-primary-outline-border-color-hover',
    BORDER_COLOR_ACTIVE: '--btn-primary-outline-border-color-active',
    BORDER_COLOR_DISABLED: '--btn-primary-outline-border-color-disabled',
  },
  PRIMARY_RED: {
    TEXT_COLOR: '--btn-primary-red-text-color',
    TEXT_COLOR_HOVER: '--btn-primary-red-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-primary-red-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-primary-red-text-color-disabled',
    BG_COLOR: '--btn-primary-red-bg-color',
    BG_COLOR_HOVER: '--btn-primary-red-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-primary-red-bg-color-active',
    BG_COLOR_DISABLED: '--btn-primary-red-bg-color-disabled',
  },
  PRIMARY_BLUE: {
    TEXT_COLOR: '--btn-primary-blue-text-color',
    TEXT_COLOR_HOVER: '--btn-primary-blue-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-primary-blue-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-primary-blue-text-color-disabled',
    BG_COLOR: '--btn-primary-blue-bg-color',
    BG_COLOR_HOVER: '--btn-primary-blue-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-primary-blue-bg-color-active',
    BG_COLOR_DISABLED: '--btn-primary-blue-bg-color-disabled',
  },
  SECONDARY: {
    TEXT_COLOR: '--btn-secondary-text-color',
    TEXT_COLOR_HOVER: '--btn-secondary-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-secondary-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-secondary-text-color-disabled',
    BG_COLOR: '--btn-secondary-bg-color',
    BG_COLOR_HOVER: '--btn-secondary-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-secondary-bg-color-active',
    BG_COLOR_DISABLED: '--btn-secondary-bg-color-disabled',
  },
  SECONDARY_DARK: {
    TEXT_COLOR: '--btn-secondary-dark-text-color',
    TEXT_COLOR_HOVER: '--btn-secondary-dark-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-secondary-dark-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-secondary-dark-text-color-disabled',
    BG_COLOR: '--btn-secondary-dark-bg-color',
    BG_COLOR_HOVER: '--btn-secondary-dark-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-secondary-dark-bg-color-active',
    BG_COLOR_DISABLED: '--btn-secondary-dark-bg-color-disabled',
  },
  SECONDARY_OUTLINE: {
    TEXT_COLOR: '--btn-secondary-outline-text-color',
    TEXT_COLOR_HOVER: '--btn-secondary-outline-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-secondary-outline-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-secondary-outline-text-color-disabled',
    BG_COLOR: '--btn-secondary-outline-bg-color',
    BG_COLOR_HOVER: '--btn-secondary-outline-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-secondary-outline-bg-color-active',
    BG_COLOR_DISABLED: '--btn-secondary-outline-bg-color-disabled',
    BORDER_COLOR: '--btn-secondary-outline-border-color',
    BORDER_COLOR_HOVER: '--btn-secondary-outline-border-color-hover',
    BORDER_COLOR_ACTIVE: '--btn-secondary-outline-border-color-active',
    BORDER_COLOR_DISABLED: '--btn-secondary-outline-border-color-disabled',
  },
};

const BUTTONS_ICON_ONLY_CSS_VARIABLES = {
  PRIMARY: {
    TEXT_COLOR: '--btn-icon-primary-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-primary-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-primary-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-primary-text-color-disabled',
    BG_COLOR: '--btn-icon-primary-bg-color',
    BG_COLOR_HOVER: '--btn-icon-primary-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-primary-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-primary-bg-color-disabled',
  },
  PRIMARY_WHITE: {
    TEXT_COLOR: '--btn-icon-primary-white-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-primary-white-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-primary-white-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-primary-white-text-color-disabled',
    BG_COLOR: '--btn-icon-primary-white-bg-color',
    BG_COLOR_HOVER: '--btn-icon-primary-white-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-primary-white-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-primary-white-bg-color-disabled',
  },
  PRIMARY_RED: {
    TEXT_COLOR: '--btn-icon-primary-red-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-primary-red-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-primary-red-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-primary-red-text-color-disabled',
    BG_COLOR: '--btn-icon-primary-red-bg-color',
    BG_COLOR_HOVER: '--btn-icon-primary-red-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-primary-red-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-primary-red-bg-color-disabled',
  },
  PRIMARY_OUTLINE: {
    TEXT_COLOR: '--btn-icon-primary-outline-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-primary-outline-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-primary-outline-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-primary-outline-text-color-disabled',
    BG_COLOR: '--btn-icon-primary-outline-bg-color',
    BG_COLOR_HOVER: '--btn-icon-primary-outline-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-primary-outline-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-primary-outline-bg-color-disabled',
    BORDER_COLOR: '--btn-icon-primary-outline-border-color',
    BORDER_COLOR_HOVER: '--btn-icon-primary-outline-border-color-hover',
    BORDER_COLOR_ACTIVE: '--btn-icon-primary-outline-border-color-active',
    BORDER_COLOR_DISABLED: '--btn-icon-primary-outline-border-color-disabled',
  },
  SECONDARY: {
    TEXT_COLOR: '--btn-icon-secondary-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-secondary-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-secondary-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-secondary-text-color-disabled',
    BG_COLOR: '--btn-icon-secondary-bg-color',
    BG_COLOR_HOVER: '--btn-icon-secondary-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-secondary-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-secondary-bg-color-disabled',
  },
  SECONDARY_TRANSPARENT: {
    TEXT_COLOR: '--btn-icon-secondary-transparent-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-secondary-transparent-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-secondary-transparent-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-secondary-transparent-text-color-disabled',
    BG_COLOR: '--btn-icon-secondary-transparent-bg-color',
    BG_COLOR_HOVER: '--btn-icon-secondary-transparent-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-secondary-transparent-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-secondary-transparent-bg-color-disabled',
  },
  SECONDARY_DARK: {
    TEXT_COLOR: '--btn-icon-secondary-dark-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-secondary-dark-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-secondary-dark-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-secondary-dark-text-color-disabled',
    BG_COLOR: '--btn-icon-secondary-dark-bg-color',
    BG_COLOR_HOVER: '--btn-icon-secondary-dark-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-secondary-dark-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-secondary-dark-bg-color-disabled',
  },
  SECONDARY_OUTLINE: {
    TEXT_COLOR: '--btn-icon-secondary-outline-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-secondary-outline-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-secondary-outline-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-secondary-outline-text-color-disabled',
    BG_COLOR: '--btn-icon-secondary-outline-bg-color',
    BG_COLOR_HOVER: '--btn-icon-secondary-outline-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-secondary-outline-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-secondary-outline-bg-color-disabled',
    BORDER_COLOR: '--btn-icon-secondary-outline-border-color',
    BORDER_COLOR_HOVER: '--btn-icon-secondary-outline-border-color-hover',
    BORDER_COLOR_ACTIVE: '--btn-icon-secondary-outline-border-color-active',
    BORDER_COLOR_DISABLED: '--btn-icon-secondary-outline-border-color-disabled',
  },
  THIRD_PARTY: {
    TEXT_COLOR: '--btn-icon-third-party-text-color',
    TEXT_COLOR_HOVER: '--btn-icon-third-party-text-color-hover',
    TEXT_COLOR_ACTIVE: '--btn-icon-third-party-text-color-active',
    TEXT_COLOR_DISABLED: '--btn-icon-third-party-text-color-disabled',
    BG_COLOR: '--btn-icon-third-party-bg-color',
    BG_COLOR_HOVER: '--btn-icon-third-party-bg-color-hover',
    BG_COLOR_ACTIVE: '--btn-icon-third-party-bg-color-active',
    BG_COLOR_DISABLED: '--btn-icon-third-party-bg-color-disabled',
  },
};

export const CSS_VARIABLES = {
  BUTTONS: BUTTON_CSS_VARIABLES,
  BUTTONS_ICON_ONLY: BUTTONS_ICON_ONLY_CSS_VARIABLES,
};
