import { colorsV2 } from './colors-v2';

export const shadows = {
  // Z-index
  zIndex1: `0px 2px 2px ${colorsV2.blackShadow5}`,
  zIndex2: `
    0 4px 8px ${colorsV2.blackShadow4},
    0 0 2px ${colorsV2.blackShadow6},
    0 0 1px ${colorsV2.blackShadow4}`,
  zIndex3: `
    0 10px 20px ${colorsV2.blackShadow4},
    0 2px 6px ${colorsV2.blackShadow4},
    0 0 1px ${colorsV2.blackShadow4}`,
  zIndex4: `
    0 16px 24px ${colorsV2.blackShadow6},
    0 2px 6px ${colorsV2.blackShadow4},
    0 0 1px ${colorsV2.blackShadow4}`,
  zIndex5: `
    0 24px 32px ${colorsV2.blackShadow4},
    0 16px 24px ${colorsV2.blackShadow4},
    0 4px 8px ${colorsV2.blackShadow4},
    0 0 1px ${colorsV2.blackShadow4}`,

  // Inner Shadow
  innerBottom: `inset 0 -1px 0 ${colorsV2.gray10}`,
  innerTop: `inset 0 1px 0 ${colorsV2.gray10}`,

  boxHover: '0px 15px 40px rgba(28, 28, 33, 0.16)',
  topZIndex1: `0px -2px 2px ${colorsV2.blackShadow5}`,
  topZIndex2: `
    0 -4px 8px ${colorsV2.blackShadow4},
    0 0 2px ${colorsV2.blackShadow6},
    0 0 1px ${colorsV2.blackShadow4}`,
  topZIndex3: `
    0px -10px 20px ${colorsV2.blackShadow4},
    0px -2px 6px ${colorsV2.blackShadow4},
    0px 0px 1px ${colorsV2.blackShadow4}`,
  topZIndex4: `
    0px -16px 24px ${colorsV2.blackShadow6},
    0px 2px 6px ${colorsV2.blackShadow4},
    0px 0px 1px ${colorsV2.blackShadow4}`,
  rightZIndex4: `16px 0px 24px ${colorsV2.blackShadow6},
    2px 0px 6px ${colorsV2.blackShadow4},
    0px 0px 1px ${colorsV2.blackShadow4}`,

  border1Bottom: 'inset 0px -1px 0px #F0F0F2',
  border1Right: 'inset -1px 0px 0px #F0F0F2',

  neutralBlueToTop1: '0px -1px 3px 0px rgba(48, 64, 120, 0.07)',

  neutralBlueToBot1: '0px 1px 3px 0px rgba(48, 64, 120, 0.07)',
  neutralBlueToBot2:
    '0px 2px 6px 0px rgba(48, 64, 120, 0.07), 0px 0px 2px 0px rgba(48, 66, 120, 0.06), 0px 0px 1px 0px rgba(48, 66, 120, 0.06)',
  neutralBlueToBot4: `
    0px 8px 24px 0px rgba(48, 66, 120, 0.08),
    0px 2px 6px 0px rgba(48, 66, 120, 0.06),
    0px 0px 1px 0px rgba(48, 66, 120, 0.06)`,

  neutralShadowToBot1: '0px 1px 3px 0px rgba(48, 64, 120, 0.07)',
  neutralShadowToBot2:
    '0px 2px 6px 0px rgba(48, 64, 120, 0.07), 0px 0px 2px 0px rgba(48, 66, 120, 0.06), 0px 0px 1px 0px rgba(48, 66, 120, 0.06)',
  neutralShadowToBot3:
    '0px 10px 20px 0px rgba(48, 64, 120, 0.06), 0px 2px 6px 0px rgba(48, 66, 120, 0.06), 0px 0px 1px 0px rgba(48, 66, 120, 0.06)',
  neutralShadowToBot4:
    '0px 12px 24px 0px rgba(48, 66, 120, 0.08), 0px 3px 6px 0px rgba(48, 66, 120, 0.06), 0px 0px 1px 0px rgba(48, 66, 120, 0.06)',
  neutralShadowToBot5:
    '0px 24px 32px 0px rgba(48, 66, 120, 0.06), 0px 16px 24px 0px rgba(48, 66, 120, 0.06), 0px 4px 8px 0px rgba(48, 66, 120, 0.06), 0px 0px 1px 0px rgba(48, 66, 120, 0.06)',
  neutralShadowToLeft3:
    '0px 0px 1px 0px rgba(48, 66, 120, 0.06), -2px 0px 6px 0px rgba(48, 66, 120, 0.06), -10px 0px 20px 0px rgba(48, 66, 120, 0.08)',
  lmsSuperHero:
    '0px 28px 56px 0px rgba(48, 66, 120, 0.08), 0px 24px 32px 0px rgba(48, 66, 120, 0.04),0px 16px 24px 0px rgba(48, 66, 120, 0.04), 0px 4px 8px 0px rgba(48, 66, 120, 0.04),0px 0px 1px 0px rgba(48, 66, 120, 0.06)',

  blueShadowToBot5: `
    0px 24px 32px 0px rgba(16, 91, 154, 0.04),
    0px 16px 24px 0px rgba(16, 91, 154, 0.04),
    0px 4px 8px 0px rgba(16, 91, 154, 0.04), 0px 0px 1px 0px rgba(16, 91, 154, 0.04)`,
};
