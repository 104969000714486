/* eslint-disable react/forbid-dom-props,@typescript-eslint/indent */
import {
  arrow,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cl from 'classnames';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { colorsV2 } from '../../colors-v2';

import { TooltipArrowMain, TooltipMain } from '../../Tooltip/shareTooltipComponents';
import {
  composeBreakPointStyle,
  numberToPxCss,
  responsiveBreakPointPropsToTrasientProps,
  ResponsiveSystemProps,
} from './ResponsiveSystem';
import { useIsomorphicLayoutEffect } from 'react-use';
import renderNode, { Span } from '../../renderNode';

const VARIANT_FONT_WEIGHT_MAPPING = {
  regular: 400,
  medium: 500,
  'semi-bold': 600,
  semibold: 600,
  bold: 700,
  bolder: 800,
  black: 900,
};

const shareStyle = css`
  color: inherit;

  &.responsive-typography.dol.dol-typo {
    margin: 0;
    ${(p) => composeBreakPointStyle(p, ['ellipsis'], customResolveAttr)}
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.break-all-text {
    word-break: break-all;
  }
`;

export interface ResponsiveTypographyProps
  extends ResponsiveSystemProps,
    Omit<React.HTMLAttributes<HTMLDivElement>, 'color'> {
  as?: string;
  variant?: string | string[];
  className?: string;
  style?: any;
  ellipsis?: any;
  children?: any;
  tooltipClassName?: string;
  tooltipPlacement?: string;
  tooltipMaxWidth?: string;
  uppercase?: boolean;
  breakAllText?: boolean;
  zIndex?: number;

  [text: string]: any;
}

const customResolveAttr = (attrName, attrValue) => {
  if (attrName === '$variant') {
    let lineHeight = '1.5em';

    const isValidVariant = Object.keys(VARIANT_FONT_WEIGHT_MAPPING).some((fontWeightName) => {
      if (attrValue) {
        return attrValue.toLowerCase().includes(fontWeightName);
      }
      return false;
    });
    if (!isValidVariant)
      throw new Error(
        `Variant name "${attrName}" with value "${attrValue}" not valid, for example: Bold/16px | 24px`
      );
    const [variantName, fontProperties] = attrValue.split('/');
    const [fontSize, lineHeightExtracted] = fontProperties.split(/\||-/g);
    if (lineHeightExtracted) {
      lineHeight = lineHeightExtracted;
    }
    return `font-weight: ${VARIANT_FONT_WEIGHT_MAPPING[variantName.trim().toLowerCase()]};
      line-height: ${numberToPxCss(lineHeight)};
      font-size: ${numberToPxCss(fontSize)};
    `;
  }
  return `${attrName}: ${attrValue};`;
};

type ResponsiveTypographyTextProps = ResponsiveTypographyProps;

const EnhancedText = styled.span.withConfig({
  componentId: 'TypoText',
})<ResponsiveTypographyTextProps>`
  ${shareStyle};
`;

const useIsOverflow = (ref: any, enabled = true, onOverflow: (hasOverflow: boolean) => void) => {
  const [isOverflow, setIsOverflow] = React.useState(undefined);

  useIsomorphicLayoutEffect(() => {
    const current = ref?.domReference?.current;
    if (enabled && current) {
      const { clientWidth, scrollWidth, clientHeight, scrollHeight } = current;
      const hasOverflow = scrollHeight > clientHeight || scrollWidth > clientWidth;
      setIsOverflow(hasOverflow);
      onOverflow?.(hasOverflow);
    }
  }, [ref, enabled]);

  return isOverflow;
};

const SymbolWrapper = styled.span`
  cursor: pointer;
  color: ${colorsV2.primary100};
`;

const useTooltipEllipsis = ({
  tooltipPlacement,
  tooltipClassName,
  tooltipMaxWidth,
  ellipsis,
  children,
  zIndex,
  color,
}) => {
  const [open, onOpenChange] = React.useState(false);
  const arrowRef = React.useRef<HTMLDivElement>(null);

  const {
    context,
    placement: floatingPlacement,
    floatingStyles,
    update,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    refs,
  } = useFloating({
    strategy: 'fixed',
    placement: tooltipPlacement,
    open,
    onOpenChange,
    middleware: [
      offset(10),
      shift(),
      flip(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useRole(context, {
      role: 'tooltip',
    }),
  ]);
  const isOverflow = useIsOverflow(
    refs,
    Boolean(ellipsis?.tooltip || ellipsis?.rows),
    ellipsis?.onEllipsis
  );
  const staticSide = React.useMemo(() => {
    return {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[floatingPlacement.split('-')[0]];
  }, [floatingPlacement]);

  const tooltipText = React.useMemo(() => {
    if (ellipsis?.tooltip === true) {
      return children;
    }
    if (ellipsis?.tooltip) {
      return ellipsis?.tooltip;
    }
  }, [children, ellipsis?.tooltip]);

  const isShowTooltip = React.useMemo(() => {
    if (isOverflow && ellipsis?.tooltip) return true;
    if (
      ellipsis?.tooltip &&
      typeof ellipsis?.tooltip !== 'boolean' &&
      ellipsis?.tooltip !== children
    ) {
      if (ellipsis?.showOnOverflowOnly === true) {
        return isOverflow;
      }
      return true;
    }
    return false;
  }, [isOverflow, ellipsis?.tooltip, ellipsis?.showOnOverflowOnly, children]);

  return {
    refs,
    update,
    getReferenceProps,
    isOverflow,
    TooltipComponent: (
      <FloatingPortal id="responsive-typography-portal">
        {isShowTooltip && open && (
          <TooltipMain
            className={tooltipClassName}
            ref={refs.setFloating}
            $maxWidth={tooltipMaxWidth}
            style={floatingStyles}
            $zIndex={zIndex}
            $color={color}
            {...getFloatingProps()}
          >
            {tooltipText}
            <TooltipArrowMain
              ref={arrowRef}
              style={{
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                [staticSide]: '-4px',
              }}
            />
          </TooltipMain>
        )}
      </FloatingPortal>
    ),
  };
};

const Text = (props: ResponsiveTypographyTextProps) => {
  const { className, tooltipClassName, tooltipPlacement, tooltipMaxWidth, ...innerProps } = props;
  const {
    children,
    style,
    ellipsis,
    uppercase = false,
    breakAllText = false,
    zIndex,
    ...rest
  } = innerProps;

  const { refs, getReferenceProps, TooltipComponent } = useTooltipEllipsis({
    ellipsis,
    children,
    tooltipClassName,
    tooltipPlacement,
    tooltipMaxWidth,
    zIndex,
    color: rest.color,
  });

  const restProps = React.useMemo(() => {
    return responsiveBreakPointPropsToTrasientProps(rest, ['variant']);
  }, [rest]);

  return (
    <>
      <EnhancedText
        ref={refs.setReference}
        className={cl(
          'responsive-typography',
          'dol',
          'dol-typo',
          {
            'with-ellipsis': Boolean(ellipsis),
            uppercase,
            'break-all-text': breakAllText,
          },
          ellipsis?.rows > 0 ? `max-row-${ellipsis?.rows}` : undefined,
          className
        )}
        children={children}
        style={style}
        {...restProps}
        {...getReferenceProps()}
      />
      {TooltipComponent}
    </>
  );
};

type ResponsiveTypographyParagraphProps = ResponsiveTypographyProps & { children?: any };

const EnhancedParagraph = styled.p.withConfig({
  componentId: 'TypoParagraph',
})<ResponsiveTypographyParagraphProps>`
  ${shareStyle};
`;

const Paragraph = (props: ResponsiveTypographyParagraphProps) => {
  const { className, tooltipClassName, tooltipPlacement, tooltipMaxWidth, ...innerProps } = props;
  const {
    children,
    style,
    ellipsis,
    uppercase = false,
    breakAllText = false,
    zIndex,
    ...rest
  } = innerProps;
  const [isShowEllipsis, setIsShowEllipsis] = React.useState(Boolean(ellipsis));
  const { update, isOverflow, refs, getReferenceProps, TooltipComponent } = useTooltipEllipsis({
    ellipsis,
    children,
    tooltipClassName,
    tooltipPlacement,
    tooltipMaxWidth,
    zIndex,
    color: rest.color,
  });

  const restProps = React.useMemo(() => {
    return responsiveBreakPointPropsToTrasientProps(rest, ['variant']);
  }, [rest]);

  return (
    <>
      <EnhancedParagraph
        ref={refs.setReference}
        className={cl(
          'responsive-typography',
          'dol',
          'dol-typo',
          {
            'with-ellipsis': isShowEllipsis,
            uppercase,
            'break-all-text': breakAllText,
          },
          ellipsis?.rows > 0 ? `max-row-${ellipsis?.rows}` : undefined,
          className
        )}
        style={style}
        ellipsis={ellipsis}
        {...restProps}
        {...getReferenceProps()}
      >
        {renderNode(Span, children, { forceUpdate: update })}
      </EnhancedParagraph>
      {isOverflow && isShowEllipsis && ellipsis?.expandable && ellipsis?.symbol && (
        <SymbolWrapper
          onClick={() => {
            if (ellipsis?.expandable) {
              setIsShowEllipsis(false);
            }
          }}
        >
          {ellipsis.symbol}
        </SymbolWrapper>
      )}
      {isOverflow && !isShowEllipsis && ellipsis?.expandable && ellipsis?.symbolNotExpand && (
        <SymbolWrapper
          onClick={() => {
            if (ellipsis?.expandable) {
              setIsShowEllipsis(true);
            }
          }}
        >
          {ellipsis.symbolNotExpand}
        </SymbolWrapper>
      )}
      {TooltipComponent}
    </>
  );
};

type ResponsiveTypographyTitleProps = ResponsiveTypographyProps & {
  level?: number;
};

const EnhancedTitle = styled.h1.withConfig({
  componentId: 'TypoTitle',
})<ResponsiveTypographyTitleProps>`
  ${shareStyle};
`;

const Title = (props: ResponsiveTypographyTitleProps) => {
  const { className, tooltipClassName, tooltipPlacement, tooltipMaxWidth, ...innerProps } = props;
  const {
    children,
    style,
    level,
    ellipsis,
    uppercase = false,
    breakAllText = false,
    zIndex,
    ...rest
  } = innerProps;

  const { refs, getReferenceProps, TooltipComponent } = useTooltipEllipsis({
    ellipsis,
    children,
    tooltipClassName,
    tooltipPlacement,
    tooltipMaxWidth,
    zIndex,
    color: rest.color,
  });

  const as = React.useMemo(() => {
    if (level) {
      switch (level) {
        case 1: {
          return 'h1';
        }
        case 2: {
          return 'h2';
        }
        case 3: {
          return 'h3';
        }
        case 4: {
          return 'h4';
        }
        case 5: {
          return 'h5';
        }
        case 6: {
          return 'h6';
        }
        default:
      }
    }
    return 'h1';
  }, [level]);

  const restProps = React.useMemo(() => {
    return responsiveBreakPointPropsToTrasientProps(rest, ['variant']);
  }, [rest]);

  return (
    <>
      <EnhancedTitle
        ref={refs.setReference}
        className={cl(
          'responsive-typography',
          'dol',
          'dol-typo',
          {
            uppercase,
            'break-all-text': breakAllText,
          },
          className
        )}
        children={children}
        style={style}
        as={as}
        {...restProps}
        {...getReferenceProps()}
      />
      {TooltipComponent}
    </>
  );
};

export const ResponsiveTypography = {
  Text: React.memo(Text),
  Paragraph: React.memo(Paragraph),
  Title: React.memo(Title),
};
