import { CSS_VARIABLES } from '@doltech/utils/lib/css-style.variables';
import cl from 'classnames';
import * as React from 'react';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';

import { colorsV2 } from '../colors-v2';
import renderNode, { Span } from '../renderNode';
import { BaseButtonProps } from './BaseButton/BaseButton';
import { ButtonIconProps } from './ButtonIcon/ButtonIcon';

const BaseButton = dynamic<BaseButtonProps>(() =>
  import('./BaseButton/BaseButton').then((mod) => mod.BaseButton as any)
);

const ButtonIcon = dynamic<ButtonIconProps>(() =>
  import('./ButtonIcon/ButtonIcon').then((mod) => mod.ButtonIcon as any)
);

const BUTTON_CSS_VARIABLES = CSS_VARIABLES.BUTTONS;

const PRIMARY_STYLES = css<{ $color: string }>`
  background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY.BG_COLOR});
  color: var(${BUTTON_CSS_VARIABLES.PRIMARY.TEXT_COLOR});

  @media (hover: hover) {
    &:hover,
    &.hover {
      background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY.BG_COLOR_HOVER});
      color: var(${BUTTON_CSS_VARIABLES.PRIMARY.TEXT_COLOR_HOVER});
    }
  }
  &:active,
  &.active {
    background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY.BG_COLOR_ACTIVE});
    color: var(${BUTTON_CSS_VARIABLES.PRIMARY.TEXT_COLOR_ACTIVE});
  }
  &:disabled,
  &.disabled {
    background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY.BG_COLOR_DISABLED});
    color: var(${BUTTON_CSS_VARIABLES.PRIMARY.TEXT_COLOR_DISABLED});
  }

  &.variant {
    &-white {
      background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_WHITE.BG_COLOR});
      color: var(${BUTTON_CSS_VARIABLES.PRIMARY_WHITE.TEXT_COLOR});

      &:hover,
      &.hover {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_WHITE.BG_COLOR_HOVER});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_WHITE.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_WHITE.BG_COLOR_ACTIVE});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_WHITE.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_WHITE.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_WHITE.TEXT_COLOR_DISABLED});
      }
    }

    &-outline {
      border-width: 2px;
      border-style: solid;
      border-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR});
      background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR});
      color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR});

      &:hover,
      &.hover {
        border-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_HOVER});
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_HOVER});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        border-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_ACTIVE});
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_ACTIVE});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        border-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_DISABLED});
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_DISABLED});
      }
    }

    &-outline-white {
      border-width: 2px;
      border-style: solid;
      border-color: ${colorsV2.white100};
      background-color: transparent;
      color: ${colorsV2.white100};

      &:disabled,
      &.disabled {
        border-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_DISABLED});
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_DISABLED});
      }
    }

    &-outline-red {
      border-width: 2px;
      border-style: solid;
      border-color: ${colorsV2.red100};
      background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR});
      color: ${colorsV2.red100};
      transition: all 0.2s ease;

      @media (hover: hover) {
        &:hover,
        &.hover {
          background-color: ${colorsV2.red100};
          color: ${colorsV2.white100};
        }
      }
      &:active,
      &.active {
        background-color: ${colorsV2.red100};
        color: ${colorsV2.white100};
        transform: scale(0.95);
      }
      &:disabled,
      &.disabled {
        border-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_DISABLED});
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_DISABLED});
      }
    }

    &-red {
      background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_RED.BG_COLOR});
      color: var(${BUTTON_CSS_VARIABLES.PRIMARY_RED.TEXT_COLOR});

      &:hover,
      &.hover {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_RED.BG_COLOR_HOVER});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_RED.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_RED.BG_COLOR_ACTIVE});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_RED.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_RED.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_RED.TEXT_COLOR_DISABLED});
      }
    }

    &-outline-dark {
      border-width: 2px;
      border-style: solid;
      border-color: ${colorsV2.black100};
      background-color: ${colorsV2.white100};
      color: ${colorsV2.black100};

      &:hover,
      &.hover {
        border-color: ${colorsV2.black100};
        background-color: transparent;
        color: ${colorsV2.black100};
      }
      &:active,
      &.active {
        border-color: ${colorsV2.black100};
        background-color: transparent;
        color: ${colorsV2.black100};
      }
      &:disabled,
      &.disabled {
        border-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_DISABLED});
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_DISABLED});
      }
    }

    &-colored {
      background-color: ${(p) => p.$color};
      color: ${colorsV2.white100};

      &:hover,
      &.hover {
        opacity: 0.8;
      }
      &:active,
      &.active {
        opacity: 0.8;
      }
      &:disabled,
      &.disabled {
        background-color: ${colorsV2.gray40};
        color: ${colorsV2.white100};
      }
    }
    &-blue {
      background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_BLUE.BG_COLOR});
      color: var(${BUTTON_CSS_VARIABLES.PRIMARY_BLUE.TEXT_COLOR});

      &:hover,
      &.hover {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_BLUE.BG_COLOR_HOVER});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_BLUE.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_BLUE.BG_COLOR_ACTIVE});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_BLUE.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        background-color: var(${BUTTON_CSS_VARIABLES.PRIMARY_BLUE.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.PRIMARY_BLUE.TEXT_COLOR_DISABLED});
      }
    }
  }
`;

const SECONDARY_STYLES = `
  background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY.BG_COLOR});
  color: var(${BUTTON_CSS_VARIABLES.SECONDARY.TEXT_COLOR});

  &:hover,
  &.hover {
    background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY.BG_COLOR_HOVER});
    color: var(${BUTTON_CSS_VARIABLES.SECONDARY.TEXT_COLOR_HOVER});
  }
  &:active,
  &.active {
    background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY.BG_COLOR_ACTIVE});
    color: var(${BUTTON_CSS_VARIABLES.SECONDARY.TEXT_COLOR_ACTIVE});
  }
  &:disabled,
  &.disabled {
    background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY.BG_COLOR_DISABLED});
    color: var(${BUTTON_CSS_VARIABLES.SECONDARY.TEXT_COLOR_DISABLED});
  }

  &.variant {

    &-black {
      background: ${colorsV2.black100};
      color: ${colorsV2.white100};
      &:hover,
      &.hover {
        background: ${colorsV2.black80};
        color: ${colorsV2.white100};
      }
      &:active,
      &.active {
        background: ${colorsV2.black80};
        color: ${colorsV2.white100};
      }
      &:disabled,
      &.disabled {
        background: ${colorsV2.gray20};
        color: ${colorsV2.black100};
      }
    }

    &-dark {
      background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_DARK.BG_COLOR});
      color: var(${BUTTON_CSS_VARIABLES.SECONDARY_DARK.TEXT_COLOR});

      &:hover,
      &.hover {
        background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_DARK.BG_COLOR_HOVER});
        color: var(${BUTTON_CSS_VARIABLES.SECONDARY_DARK.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_DARK.BG_COLOR_ACTIVE});
        color: var(${BUTTON_CSS_VARIABLES.SECONDARY_DARK.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_DARK.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.SECONDARY_DARK.TEXT_COLOR_DISABLED});
      }
    }

    &-outline {
      border-width: 2px;
      border-style: solid;
      border-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.BORDER_COLOR});
      background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.BG_COLOR});
      color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.TEXT_COLOR});

      &:hover,
      &.hover {
        border-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.BORDER_COLOR_HOVER});
        background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.BG_COLOR_HOVER});
        color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        border-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.BORDER_COLOR_ACTIVE});
        background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.BG_COLOR_ACTIVE});
        color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        border-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.BORDER_COLOR_DISABLED});
        background-color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.BG_COLOR_DISABLED});
        color: var(${BUTTON_CSS_VARIABLES.SECONDARY_OUTLINE.TEXT_COLOR_DISABLED});
      }
    }
  }
`;

const LINK_STYLES = `
  background-color: unset;
  padding: 0;
  .btn-item {
    display: flex;
    align-items: center;
  }
  &:hover,
  &.hover {
    opacity: 70%;
  }
  &:active,
  &.active {
    opacity: 1;
  }
  &:disabled,
  &.disabled {
    opacity: 40%;
  }
`;

const StyledButton = styled(BaseButton).withConfig({
  componentId: 'ButtonV2_StyledButton',
} as any)<{ $color: string }>`
  &.size-large {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding: 8px 16px;

    &.variant-outline,
    &.variant-outline-red {
      padding: 6px 14px;
    }

    .btn-icon-wrapper {
      font-size: 24px;
    }
  }

  &.size-small {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;

    &.variant-outline,
    &.variant-outline-red {
      padding: 4px 10px;
    }

    .btn-icon-wrapper {
      font-size: 18px;
    }
  }

  &.full-width {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
  }

  &.fit-parent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &.type-primary {
    ${PRIMARY_STYLES}
  }

  &.type-secondary {
    ${SECONDARY_STYLES}
  }

  &.type-link {
    ${LINK_STYLES}
  }

  .btn-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    .btn-item {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  .btn-icon-wrapper {
    display: flex;
  }
`;

export interface ButtonProps extends BaseButtonProps {
  size?: 'large' | 'small' | 'custom';
  type?: 'default' | 'primary' | 'secondary' | 'link';
  variant?:
    | 'normal'
    | 'white'
    | 'red'
    | 'dark'
    | 'black'
    | 'outline'
    | 'outline-white'
    | 'outline-red'
    | 'outline-dark'
    | 'colored'
    | 'blue';
  color?: string;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  fullWidth?: boolean;
  fitParent?: boolean; // TODO: Remove and change to fullWidth with size
}

export const Button = (props: ButtonProps) => {
  const {
    className,
    size = 'large',
    type = 'default',
    variant = 'normal',
    children,
    iconLeft,
    iconRight,
    fullWidth = false,
    fitParent = false,
    color,
    ...rest
  } = props;

  return (
    <StyledButton
      className={cl(
        `size-${size} type-${type} variant-${variant}`,
        { 'full-width': fullWidth, 'fit-parent': fitParent },
        className
      )}
      $color={color}
      {...rest}
    >
      <span className="btn-layout">
        {iconLeft && (
          <span className="btn-item btn-icon-wrapper icon-left">
            {renderNode(Span, iconLeft, {})}
          </span>
        )}
        {children && <span className="btn-item">{children}</span>}
        {iconRight && (
          <span className="btn-item btn-icon-wrapper icon-right">
            {renderNode(Span, iconRight, {})}
          </span>
        )}
      </span>
    </StyledButton>
  );
};

const ButtonPrimary = ({ children, ...rest }: ButtonProps) => (
  <Button type="primary" {...rest}>
    {children}
  </Button>
);
const ButtonSecondary = ({ children, ...rest }: ButtonProps) => (
  <Button type="secondary" {...rest}>
    {children}
  </Button>
);
const ButtonLink = ({ children, ...rest }: ButtonProps) => (
  <Button type="link" {...rest}>
    {children}
  </Button>
);

Button.Primary = ButtonPrimary;
Button.Secondary = ButtonSecondary;
Button.Icon = ButtonIcon;
Button.Link = ButtonLink;
